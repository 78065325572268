const PRESCRIBING_INFORMATION =
    'https://media.allergan.com/actavis/actavis/media/allergan-pdf-documents/product-prescribing/20190620-BOTOX-100-and-200-Units-v3-0USPI1145-v2-0MG1145.pdf'
const MEDICATION_GUIDE =
    'https://media.allergan.com/actavis/actavis/media/allergan-pdf-documents/product-prescribing/20190620_BTX-and-BTX-C-Med-Guide-v2-0MG1145.pdf'
const HOME = '/'
const CONTACT = 'https://www.abbvie.com/contactus.html'
const PRIVACY = 'https://www.abbvie.com/privacy.html'
const TERMS = 'https://www.abbvie.com/termsofuse.html'
const ABOUT = 'https://www.abbvie.com/our-company.html'
const ALLERGAN_HOME = 'https://www.allerganaesthetics.com'

export default {
    isi: {
        PRESCRIBING_INFORMATION,
        MEDICATION_GUIDE
    },
    footer: {
        HOME,
        ALLERGAN_HOME,
        CONTACT,
        PRIVACY,
        TERMS,
        ABOUT
    }
}
