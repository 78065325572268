<template>
    <div class="component">
        <p class="video-section-label">Videos</p>
        <div class="columns">
            <div class="column video">
                <p class="video-title">Muscle Function Animation</p>
                <vimeo-player :vimeo-id="videos.muscle_function_animation.vimeo_id"></vimeo-player>
            </div>
            <div class="column video">
                <p class="video-title">Cadaver Demonstration</p>
                <vimeo-player :vimeo-id="videos.cadaver_demonstration.vimeo_id"></vimeo-player>
            </div>
        </div>
    </div>
</template>

<script>
import VimeoPlayer from './VimeoPlayer'

export default {
    name: 'VideoGrid',
    props: {
        videos: {
            type: Object,
            required: true
        }
    },
    components: {
        VimeoPlayer
    }
}
</script>

<style lang="scss">
.video-section-label {
    color: $light-purple;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
}
.video-title {
    color: $purple;
    font-weight: 500;
    margin-bottom: 0.6rem;
}
.column.video {
    height: 100%;
}
.muscle-function-animation {
    .plyr--video,
    .plyr__video-wrapper,
    .plyr__poster {
        background-color: whitesmoke !important;
    }
}
</style>
