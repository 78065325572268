<template>
    <footer class="footer">
        <div class="container">
            <div class="columns is-flex is-flex-direction-column is-gapless is-marginless">
                <div class="column">
                    <a class="allergan-logo" :href="links.footer.ALLERGAN_HOME" target="_blank" aria-current="page">
                        <object :data="allerganLogo" type="image/svg+xml">
                            <img src="@/assets/images/allergan-logo.png" width="163" height="47" />
                        </object>
                    </a>
                </div>
            </div>
            <div class="column is-size-7">
                BOTOX&reg; and its design are registered trademarks of Allergan, Inc., an AbbVie company.<br />
                &copy; 2022 AbbVie. All rights reserved. US-BTX-211853 01/22 007081
            </div>
            <div class="column">
                <div class="columns is-mobile">
                    <div class="column footer-links is-flex">
                        <div><a :href="links.footer.HOME" target="_blank">Home</a></div>
                        <div><a :href="links.footer.CONTACT" target="_blank">Contact Us</a></div>
                        <div class="is-hidden-touch"><a :href="links.footer.PRIVACY" target="_blank">Privacy Statement</a></div>
                        <div class="is-hidden-touch"><a :href="links.footer.TERMS" target="_blank">Terms of Use</a></div>
                        <div class="is-hidden-touch">
                            <a :href="links.footer.ABOUT" target="_blank">About Allergan</a><sup>&reg;</sup>
                        </div>
                    </div>
                    <div class="column footer-links is-flex is-hidden-desktop">
                        <div><a :href="links.footer.PRIVACY" target="_blank">Privacy Statement</a></div>
                        <div><a :href="links.footer.TERMS" target="_blank">Terms of Use</a></div>
                        <div><a :href="links.footer.ABOUT" target="_blank">About Allergan</a><sup>&reg;</sup></div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import links from '../../constants/links'
export default {
    data() {
        return {
            allerganLogo: require('@/assets/images/allergan-logo.svg'),
            links
        }
    }
}
</script>

<style lang="sass">
@import '~bulma/sass/utilities/mixins'
+touch
    .footer-links
        flex-direction: column
        &> div
            margin-left: 0 !important
            margin-top: 1rem
</style>

<style>
footer.footer {
    background-color: #000000;
    color: #ffffff;
}
.footer .allergan-logo {
    position: relative;
    display: inline-block;
}
.footer-links > div {
    margin-left: 2.5rem;
}
.footer-links > div:first-child {
    margin-left: 0;
}
.footer-links a {
    color: #ffffff;
    font-size: 0.75rem;
    text-decoration: underline;
}
.footer-links sup,
sub {
    vertical-align: baseline;
    position: relative;
    top: -0.2em;
}
.footer-links sub {
    top: 0.4em;
}
</style>
