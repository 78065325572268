<template>
    <section class="section isi-section">
        <div class="isi">
            <div class="has-text-weight-bold">IMPORTANT SAFETY INFORMATION, INCLUDING BOXED WARNING</div>
            <div class="boxed-warning">
                <div>WARNING: DISTANT SPREAD OF TOXIN EFFECT</div>
                <div class="line-break">
                    Postmarketing reports indicate that the effects of BOTOX® and all botulinum toxin products may spread from the
                    area of injection to produce symptoms consistent with botulinum toxin effects. These may include asthenia,
                    generalized muscle weakness, diplopia, ptosis, dysphagia, dysphonia, dysphonia, dysarthria, urinary
                    incontinence, and breathing difficulties. These symptoms have been reported hours to weeks after injection.
                    Swallowing and breathing difficulties can be life threatening, and there have been reports of death. The risk
                    of symptoms is probably greatest in children treated for spasticity, but symptoms can also occur in adults
                    treated for spasticity and other conditions, particularly in those patients who have an underlying condition
                    that would predispose them to these symptoms. In unapproved uses and approved indications, cases of spread of
                    effect have been reported at doses comparable to those used to treat Cervical Dystonia and spasticity and at
                    lower doses.
                </div>
            </div>
            <div class="has-text-weight-bold line-break">
                Indications<br />
                Spasticity
            </div>
            <div class="body">
                BOTOX® for injection is indicated for the treatment of spasticity in patients 2 years of age and older.
            </div>
            <div class="has-text-weight-bold line-break">Limitations of Use</div>
            <div class="body">
                BOTOX® has not been shown to improve upper extremity functional abilities or range of motion at a joint affected
                by a fixed contracture.
            </div>
            <div class="has-text-weight-bold line-break">Cervical Dystonia</div>
            <div class="body">
                BOTOX® is indicated for the treatment of adults with Cervical Dystonia to reduce the severity of abnormal head
                position and neck pain associated with Cervical Dystonia.
            </div>
            <div class="has-text-weight-bold line-break">IMPORTANT SAFETY INFORMATION (continued)<br />CONTRAINDICATIONS</div>
            <div class="body">
                BOTOX® is contraindicated in the presence of infection at the proposed injection site(s) and in patients who are
                hypersensitive to any botulinum toxin product or to any of the components in the formulation.
            </div>
            <div class="has-text-weight-bold line-break">WARNINGS AND PRECAUTIONS</div>
            <div class="body">
                <strong>Spread of Toxin Effect</strong><br />
                See Boxed Warning.
            </div>
            <div class="body line-break">
                <strong>
                    Lack of Interchangeability Between Botulinum Toxin Products<br />
                    The potency Units of BOTOX® are specific to the preparation and assay method utilized. They are not
                    interchangeable with other preparations of botulinum toxin products and, therefore, Units of biological
                    activity of BOTOX® cannot be compared to nor converted into Units of any other botulinum toxin products
                    assessed with any other specific assay method.
                </strong>
            </div>
            <div class="has-text-weight-bold purple line-break">Serious Adverse Reactions With Unapproved Use</div>
            <div class="body">
                Serious adverse reactions, including excessive weakness, dysphagia, and aspiration pneumonia, with some adverse
                reactions associated with fatal outcomes, have been reported in patients who received BOTOX® injections for
                unapproved uses. In these cases, the adverse reactions were not necessarily related to distant spread of toxin,
                but may have resulted from the administration of BOTOX® to the site of injection and/or adjacent structures. In
                several of the cases, patients had pre-existing dysphagia or other significant disabilities. There is insufficient
                information to identify factors associated with an increased risk for adverse reactions associated with the
                unapproved uses of BOTOX®. The safety and effectiveness of BOTOX® for unapproved uses have not been established.
            </div>
            <div class="has-text-weight-bold purple line-break">Hypersensitivity Reactions</div>
            <div class="body">
                Serious and/or immediate hypersensitivity reactions have been reported. These reactions include anaphylaxis, serum
                sickness, urticaria, soft-tissue edema, and dyspnea. If such a reaction occurs, further injection of BOTOX® should
                be discontinued and appropriate medical therapy immediately instituted. One fatal case of anaphylaxis has been
                reported in which lidocaine was used as the diluent, and consequently the causal agent cannot be reliably
                determined.
            </div>
            <div class="has-text-weight-bold purple line-break">
                Increased Risk of Clinically Significant Effects With Pre-existing Neuromuscular Disorders
            </div>
            <div class="body">
                Individuals with peripheral motor neuropathic diseases, amyotrophic lateral sclerosis (ALS), or neuromuscular
                junction disorders (eg, myasthenia gravis or Lambert-Eaton syndrome) should be monitored when given botulinum
                toxin. Patients with known or unrecognized neuromuscular disorders or neuromuscular junction disorders may be at
                increased risk of clinically significant effects, including generalized muscle weakness, diplopia, ptosis,
                dysphonia, dysarthria, severe dysphagia, and respiratory compromise from therapeutic doses of BOTOX® (see
                <em>Warnings and Precautions</em>).
            </div>
            <div class="has-text-weight-bold purple line-break">Dysphagia and Breathing Difficulties</div>
            <div class="body">
                Treatment with BOTOX® and other botulinum toxin products can result in swallowing or breathing difficulties.
                Patients with pre-existing swallowing or breathing difficulties may be more susceptible to these complications. In
                most cases, this is a consequence of weakening of muscles in the area of injection that are involved in breathing
                or oropharyngeal muscles that control swallowing or breathing (see <em>Boxed Warning</em>).
            </div>
            <div class="has-text-weight-bold purple line-break">
                Pulmonary Effects of BOTOX® in Patients With Compromised Respiratory Status Treated for Spasticity
            </div>
            <div class="body">
                Patients with compromised respiratory status treated with BOTOX® for spasticity should be monitored closely.
            </div>
            <div class="has-text-weight-bold purple line-break">
                Bronchitis and Upper Respiratory Tract Infections in Patients Treated for Spasticity
            </div>
            <div class="body">
                Bronchitis was reported more frequently as an adverse reaction in adult patients treated for upper limb spasticity
                with BOTOX® (3% at 251 Units to 360 Units total dose) compared to placebo (1%). In adult patients with reduced
                lung function treated for upper limb spasticity, upper respiratory tract infections were also reported more
                frequently as adverse reactions in patients treated with BOTOX® (11% at 360 Units total dose; 8% at 240 Units
                total dose) compared to placebo (6%). In adult patients treated for lower limb spasticity, upper respiratory tract
                infections were reported more frequently as an adverse reaction in patients treated with BOTOX® (2% at 300 Units
                to 400 Units total dose) compared to placebo (1%).
            </div>
            <div class="has-text-weight-bold purple line-break">Human Albumin and Transmission of Viral Diseases</div>
            <div class="body">
                This product contains albumin, a derivative of human blood. Based on effective donor screening and product
                manufacturing processes, it carries an extremely remote risk for transmission of viral diseases and variant
                Creutzfeldt-Jakob disease (vCJD). There is a theoretical risk for transmission of Creutzfeldt-Jakob disease (CJD),
                but if that risk actually exists, the risk of transmission would also be considered extremely remote. No cases of
                transmission of viral diseases, CJD, or vCJD have ever been identified for licensed albumin or albumin contained
                in other licensed products.
            </div>
            <div class="has-text-weight-bold line-break">ADVERSE REACTIONS</div>
            <div class="body">
                Adverse reactions to BOTOX® for injection are discussed in greater detail in the following sections:
                <em>Boxed Warning</em>, <em>Contraindications</em>, and <em>Warnings and Precautions</em>.
            </div>
            <div class="has-text-weight-bold purple line-break">Adult Upper Limb Spasticity</div>
            <div class="body">
                The most frequently reported adverse reactions following injection of BOTOX® for upper limb spasticity include
                pain in extremity, muscular weakness, fatigue, nausea, and bronchitis.
            </div>
            <div class="has-text-weight-bold purple line-break">Adult Lower Limb Spasticity</div>
            <div class="body">
                The most frequently reported adverse reactions following injection of BOTOX® for lower limb spasticity include
                arthralgia, back pain, myalgia, upper respiratory tract infection, and injection-site pain.
            </div>
            <div class="has-text-weight-bold purple line-break">Cervical Dystonia</div>
            <div class="body">
                The most frequently reported adverse reactions following injection of BOTOX® for Cervical Dystonia include
                dysphagia (19%), upper respiratory infection (12%), neck pain (11%), and headache (11%).
            </div>
            <div class="has-text-weight-bold purple line-break">Postmarketing Experience</div>
            <div class="body">
                Adverse reactions that have been identified during postapproval use of BOTOX® are discussed in greater detail in
                Postmarketing Experience (Section 6.3 of the Prescribing Information).
            </div>
            <div class="body line-break">
                There have been spontaneous reports of death, sometimes associated with dysphagia, pneumonia, and/or other
                significant debility or anaphylaxis, after treatment with botulinum toxin. There have also been reports of adverse
                events involving the cardiovascular system, including arrhythmia and myocardial infarction, some with fatal
                outcomes. Some of these patients had risk factors including cardiovascular disease. The exact relationship of
                these events to the botulinum toxin injection has not been established.
            </div>
            <div class="has-text-weight-bold line-break">DRUG INTERACTIONS</div>
            <div class="body">
                Co-administration of BOTOX® and other agents interfering with neuromuscular transmission (eg, aminoglycosides,
                curare-like compounds) should only be performed with caution as the effect of the toxin may be potentiated. Use of
                anticholinergic drugs after administration of BOTOX® may potentiate systemic anticholinergic effects. The effect
                of administering different botulinum neurotoxin products at the same time or within several months of each other
                is unknown. Excessive neuromuscular weakness may be exacerbated by administration of another botulinum toxin prior
                to the resolution of the effects of a previously administered botulinum toxin. Excessive weakness may also be
                exaggerated by administration of a muscle relaxant before or after administration of BOTOX®.
            </div>
            <div class="body line-break">
                <strong>
                    Please see BOTOX® full
                    <a :href="links.isi.PRESCRIBING_INFORMATION" target="_blank">Prescribing Information</a>, including Boxed
                    Warning and <a :href="links.isi.MEDICATION_GUIDE" target="_blank">Medication Guide</a>.
                </strong>
            </div>
        </div>
    </section>
</template>

<script>
import links from '../../constants/links'

export default {
    data() {
        return { links }
    }
}
</script>

<style lang="scss">
.isi-section {
    padding-top: 30px !important;
}
.isi {
    font-size: 0.75rem;

    .body {
        color: $purple;
    }
    .body strong {
        color: $dark-purple;
    }
    .body strong a {
        color: $light-purple;
        text-decoration: underline;
    }
    .has-text-weight-bold {
        color: $light-purple;
    }
    .purple {
        color: $purple;
    }
    .boxed-warning div {
        color: $purple;
        font-weight: bold;
    }
    .boxed-warning {
        border: solid thin #000000;
        padding: 15px 10px;
        margin-top: 20px;
    }
    .line-break {
        padding-top: 20px;
    }
}
</style>
