<template>
    <div class="component">
        <b-skeleton :rounded="false" :active="!hasVideoLoaded" height="268px"></b-skeleton>
        <div class="player-container" v-show="hasVideoLoaded">
            <div ref="plyr" data-plyr-provider="vimeo" :data-plyr-embed-id="vimeoId"></div>
        </div>
    </div>
</template>
<script>
import Plyr from 'plyr'

export default {
    name: 'VimeoPlayer',
    props: {
        vimeoId: String,
        options: {
            type: Object,
            required: false,
            default() {
                return {
                    vimeo: {
                        responsive: true,
                        playsinline: true
                    }
                }
            }
        }
    },
    data() {
        return {
            player: {},
            hasVideoLoaded: false
        }
    },
    mounted() {
        this.player = new Plyr(this.$refs.plyr, this.options)

        const self = this
        this.player.on('ratechange', function () {
            self.$nextTick(() => {
                self.hasVideoLoaded = true
            })
        })
    },
    watch: {
        vimeoId: function (val) {
            this.hasVideoLoaded = false
            this.player.source = {
                type: 'video',
                sources: [
                    {
                        src: val,
                        provider: 'vimeo'
                    }
                ]
            }
        }
    },
    beforeUnmount() {
        try {
            this.player.destroy()
        } catch (e) {
            if (!(this.opts.hideYouTubeDOMError && e.message === 'The YouTube player is not attached to the DOM.')) {
                // eslint-disable-next-line no-console
                console.error(e)
            }
        }
    }
}
</script>

<style>
video {
    object-fit: cover;
}
</style>
